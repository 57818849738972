/*
 * @Author: N0ts
 * @Date: 2024-01-29 15:04:41
 * @Description: main
 * @FilePath: \eazy-gitee-note\src\main.ts
 * @Mail：mail@n0ts.top
 */
import { createApp } from "vue";
import App from "./App.vue";

import "@/assets/css/main.less";
import "@/assets/css/theme.less";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

const app = createApp(App);
app.use(ElementPlus, {
    locale: zhCn
});
app.mount("#app");

// 解决 ResizeObserver loop completed with undelivered notifications. 错误，原因未知
const debounce = (fn: any, delay: number) => {
    let timer: any = null;
    return function () {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        let context = this;
        // eslint-disable-next-line
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback: any) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
