import { ref } from "vue";

const themeCache = ref<any>("");
const key = "n0ts-note-theme";

function setLocalStorage(theme: string) {
    window.localStorage.setItem(key, theme);
    themeCache.value = theme;
}

function load() {
    const cache = window.localStorage.getItem(key);
    if (!cache) {
        setLocalStorage("light");
    } else {
        themeCache.value = cache;
    }
    document.documentElement.setAttribute("theme", cache || "light");
}

function switchTheme() {
    themeCache.value = window.localStorage.getItem(key);
    themeCache.value = themeCache.value === "light" ? "dark" : "light";
    setLocalStorage(themeCache.value);
    load();
}

export default {
    switchTheme,
    load,
    themeCache
};
